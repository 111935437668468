import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import ProgressBar from 'primevue/progressbar';
import Chart from 'primevue/chart';
import VuePlyr from 'vue-plyr'
import { menuType } from "./modules/menu-management";


//#region 프라임뷰 관련 스타일 파일 선언
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.min.css';
import 'primeicons/primeicons.css';
//#endregion

import '@/assets/css/all.min.css';
import '@/assets/css/koreit_common.css';
import '@/assets/css/koreit_sub.css';

// vue-plyr css
import 'vue-plyr/dist/vue-plyr.css'

// aos
import "aos/dist/aos.css";
import "aos/dist/aos.js";


const app = createApp(App);

app.use(store);
app.use(router);
app.use(VuePlyr, {
    plyr: {},
});

// 프라임뷰 설정
app.use(PrimeVue, {
    locale: {
        //#region default
        noFilter: 'No Filter',
        lt: 'Less than',
        lte: 'Less than or equal to',
        gt: 'Greater than',
        gte: 'Greater than or equal to',
        dateIs: 'Date is',
        dateIsNot: 'Date is not',
        dateBefore: 'Date is before',
        dateAfter: 'Date is after',
        matchAll: 'Match All',
        matchAny: 'Match Any',
        addRule: 'Add Rule',
        removeRule: 'Remove Rule',
        choose: '첨부',
        upload: '업로드',
        cancel: '취소',
        chooseYear: 'Choose Year',
        chooseMonth: 'Choose Month',
        chooseDate: 'Choose Date',
        prevDecade: 'Previous Decade',
        nextDecade: 'Next Decade',
        prevYear: 'Previous Year',
        nextYear: 'Next Year',
        prevMonth: 'Previous Month',
        nextMonth: 'Next Month',
        prevHour: 'Previous Hour',
        nextHour: 'Next Hour',
        prevMinute: 'Previous Minute',
        nextMinute: 'Next Minute',
        prevSecond: 'Previous Second',
        nextSecond: 'Next Second',
        weekHeader: 'Wk',
        firstDayOfWeek: 0,
        weak: 'Weak',
        medium: 'Medium',
        strong: 'Strong',
        passwordPrompt: 'Enter a password',
        emptyFilterMessage: 'No results found', // @deprecated Use 'emptySearchMessage' option instead.
        searchMessage: '{0} results are available',
        selectionMessage: '{0} items selected',
        emptySelectionMessage: 'No selected item',
        emptySearchMessage: 'No results found',
        emptyMessage: 'No available options',
        aria: {
            trueLabel: 'True',
            falseLabel: 'False',
            nullLabel: 'Not Selected',
            star: '1 star',
            stars: '{star} stars',
            selectAll: 'All items selected',
            unselectAll: 'All items unselected',
            close: 'Close',
            previous: 'Previous',
            next: 'Next'
        },
        //#endregion
        
        accept: 'Yes',
        reject: 'No',
        dayNames: ["일", "월", "화", "수", "목", "금", "토"],
        dayNamesShort: ["일", "월", "화", "수", "목", "금", "토"],
        //캘린더에 표시되는 요일 
        dayNamesMin: ["일","월","화","수","목","금","토"],
        //캘린더에 표시되는 월 
        monthNames: ["1월","2월","3월","4월","5월","6월","7월","8월","9월","10월","11월","12월"],
        //월 선택시 표시되는 월
        monthNamesShort: ["1월", "2월", "3월", "4월", "5월", "6월","7월", "8월", "9월", "10월", "11월", "12월"],
        clear: '닫기',
        am: '오전',
        pm: '오후',
        today: '오늘',
        dateFormat: 'yy-mm-dd',
        apply: '적용',
        startsWith: '시작되는',
        contains: '포함',
        notContains: '제외',
        endsWith: '끝나는',
        equals: '같은',
        notEquals: '다른',
    }
});

//#region 사용할 프라임 뷰 컴포넌트들 선언 (전역적으로 사용가능)
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("Dialog", Dialog);
app.component("ProgressBar", ProgressBar);
app.component("Chart", Chart);


app.mount("#wrap");

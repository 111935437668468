<template>
    <router-view />
    <div class="progress-popup" style="display:none;" v-show="store.getters.isLoading">
        <div class="progress-inner check">
            <div>
                <p>처리중입니다</p>
                <span>잠시만 기다려 주세요</span>
                <ProgressBar mode="indeterminate"></ProgressBar>
            </div>
        </div>
    </div>
</template>
<script>
import { useStore } from 'vuex'
export default {

    setup() {
        
        const store = useStore();

        return {
            store,
        }
    },
}
</script>
<style>
    /* sweetalert2 Index property */
    .swal2-container {
        z-index: 20000 !important;
    }
    .text-overflow { 
        width: 500px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .a-hover a:hover {color:#1d4ed8; font-weight:600;}
</style>